import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const JourneyImage = ({ src }) => {
  const data = useStaticQuery(graphql`
    query {
      apprentice: file(relativePath: { eq: "apprentice-welder.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      host: file(relativePath: { eq: "employer-and-manager.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      {src === "apprentice" ? (
        <Img
          fluid={data.apprentice.childImageSharp.fluid}
          imgStyle={{ objectPosition: "90%" }}
        />
      ) : src === "host" ? (
        <Img fluid={data.host.childImageSharp.fluid} />
      ) : (
        <div/>
      )}
    </>
  )
}
