import React from "react"
import { motion } from "framer-motion"
import { JourneyImage } from "../components/gatsby-image/journey-images"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { stagger, ease } from "../utils/animations"
import { HeaderLogo } from "./header"

const ChooseJourney = ({children}) => {
  return (
    <>
      <div className="journey-logo" style={{ position: "absolute" }}>
        <HeaderLogo />
      </div>

      <div className="journey-choose-cnt">
        <div className="journey-cnt">
          <div className="question">{children}</div>
          <motion.div
            className="journey-items"
            variants={stagger}
            initial="initial"
            animate="animate"
          >
            {items.map(({ link, audience, leadIn, src }) => (
              <JourneyCard
                link={link}
                audience={audience}
                leadIn={leadIn}
                key={audience}
                src={src}
              />
            ))}
          </motion.div>
        </div>
      </div>
    </>
  )
}

export default ChooseJourney

const JourneyCard = ({ link, audience, leadIn, src }) => {
  return (
    <motion.div
      variants={fadeUp}
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.03, transition: { ease: ease } }}
      className="motion-card"
    >
      <AniLink fade duration={.7} to={link} className="journey-card">
        <div className="content">
          <div>{leadIn}</div>
          <h2>{audience}</h2>
        </div>
        <div className="image">
          <JourneyImage src={src} />
        </div>
      </AniLink>
    </motion.div>
  )
}

const items = [
  {
    link: "/apprentices",
    leadIn: "I'm thinking of being an",
    audience: "apprentice",
    src: "apprentice",
  },
  {
    link: "/businesses",
    leadIn: "I own or manage a",
    audience: "business",
    src: "host",
  },
]

const fadeUp = {
  initial: { opacity: 0, y: 30 },
  animate: { opacity: 1, y: 0 },
}
